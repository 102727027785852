import { log } from "console";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import RemainingTimeNftCard from "./RemainingTimeNftCard";


export interface CardNFT2Props {
  className?: string;
  isLiked?: boolean;
  data?: any;
}

const CardNFT2: FC<CardNFT2Props> = ({ className = "", isLiked, data }) => {

  return (
    <div
      className={`nc-CardNFT2 relative bg-white dark:bg-neutral-900 rounded-3xl flex flex-col group p-2.5  ${className}`}
      data-nc-id="CardNFT2"
    >
      <div className="relative flex-shrink-0 ">
        <div>
          <NcImage
            containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0 rounded-3xl overflow-hidden z-0"
            src={data?.image}
            className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
          />
        </div>

        {/* ----TIME--- */}
        <RemainingTimeNftCard contentClassName="right-5 top-1/2 -translate-y-1/2 pb-1" date={data.days_left} />

        <div className="absolute left-[-1px] bottom-[-0.4px] ">
          <svg
            className="text-white dark:text-neutral-900 w-64 md:w-[281px]"
            width="281"
            viewBox="0 0 281 99"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 0V99H258.059C248.54 99 239.92 93.3743 236.089 84.6606L205.167 14.3394C201.335 5.62568 192.716 0 183.197 0H0Z"
              fill="currentColor"
            />
          </svg>

          <div className="absolute left-4 bottom-0 w-48 ">
            <h2 className={`text-lg font-semibold capitalize`}>
              {data?.company_name}
            </h2>

           

            <div className="w-full mt-1.5 flex justify-between items-end ">
              {/* <Prices2 /> */}
              <div className="flex flex-col sm:flex-row sm:items-end sm:justify-between">
                <div className="flex-1 flex flex-col sm:flex-row items-baseline border-2 border-[#F1A300] rounded-xl relative py-1 px-2">
                  <span className="text-md xl:text-md font-semibold text-[#F1A300]">{data.tokens_id[0].price * data.tokens_id[0].sold} / {data.tokens_id[0].price * data.tokens_id[0].token_initial_supply} USD</span>
                  </div>
                  </div>
             
            </div>
          </div>
        </div>
      </div>

      <Link to={!data.confidentiality  ? `/project-details/${data.id}` : '/'} className="absolute inset-0"></Link>

      <div className="my-4 mx-4">
         <span className="block text-neutral-500 dark:text-neutral-400 text-xs pb-1">
                {data.tokens_id[0].sold}/{data.tokens_id[0].token_initial_supply} Token Sold
              </span>
        <span className="block text-xs text-neutral-500 dark:text-neutral-400 tracking-wide pb-1">
          <span className="font-semibold">Promise :</span> {data?.promise_id[0]?.revenue[0]}% Revenue / {data?.promise_id[0]?.revenue[1]}y Perf
        </span>
        <span className="block text-xs text-neutral-500 dark:text-neutral-400 tracking-wide pb-1">
          <span className="font-semibold">{data?.promise_id[0]?.support}</span>  People Are Supporting
        </span>
        <span className="block text-xs text-neutral-500 dark:text-neutral-400 tracking-wide pb-1">
          <span className="font-semibold">{data?.promise_id[0]?.organisations}</span> COMPANIES  AND ORGANISATIONS
        </span>
        <div className="w-full flex flex-row mt-2 gap-2">
          {data?.impact_id.slice(0,3).map((tag: Array<any>, index: number) => 
          <div className="bg-neutral-800 hover:bg-primary-700 rounded-full w-fit" key={index}>
          <h2 className="px-4 py-1 text-white text-xs">#{tag[0].tag}</h2>
          
          </div>
          )}
        </div>
      </div>
      <div className="absolute top-[-10px] left-[-10px]">
        <div className="bg-primary-6000 hover:bg-primary-700 rounded-full">
          <h2 className="px-4 py-1 text-white font-medium text-[13px]">{data?.fundraising_stages_id[0].title}</h2>
        </div>
      </div>
    </div>
  );
};

export default CardNFT2;
