import React, { useState } from "react";
import SectionSliderCategories from "components/SectionSliderCategories/SectionSliderCategories";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import { Helmet } from "react-helmet";
import SectionLargeSlider from "./SectionLargeSlider";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionHero2 from "components/SectionHero/SectionHero2";
import SectionGridFeatureNFT2 from "./SectionGridFeatureNFT2";
import Ads from "components/Ads";
import Partners from "components/Partners";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from "axios";
import { toast } from "react-toastify";

const info = [
  {
    title: "🗺 ADDRESS",
    desc: "TECHNOPARK Agadir,MOROCCO.",
  },
  {
    title: "✉️ EMAIL",
    desc: "funding@thebay.ma",
  },
  {
    title: "☎ PHONE",
    desc: "+1 786 933 6054",
  },
];

function PageHome() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [contactLoading, setContactLoading] = useState(false);

  const handleFullNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFullName(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setMessage(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent) => {
    // prevent the default form submission behavior
    setContactLoading(true);
    event.preventDefault();

    const data = {
      full_name: fullName,
      email: email,
      message: message,
    };

    axios
      .post("https://x8ki-letl-twmt.n7.xano.io/api:e0mV796I/contact", data)
      .then((response) => {
        setContactLoading(false);
        toast.success("Message sent successfully");
        setFullName("");
        setEmail("");
        setMessage("");
      })
      .catch((error) => {
        // handle error
        console.log(error);
        setContactLoading(false);
      });
  };

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>Funding Startups</title>
      </Helmet>
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative mt-5 mb-20 sm:mb-24 lg:mt-20 lg:mb-32">
        {/* SECTION HERO */}
        <SectionHero2 />

        {/* SECTION 2 */}
        <SectionHowItWork className="mt-24 lg:mt-40 xl:mt-48" />

        <Ads />

        <div className="relative py-20 lg:py-20 mt-20">
          <BackgroundSection className="bg-neutral-100/70 dark:bg-black/20 " />
          <SectionGridFeatureNFT2 />
        </div>
      </div>

      {/* SECTION LAERGE SLIDER */}
      {/* <div className="bg-neutral-100/70 dark:bg-black/20 py-20 lg:py-32">
        <div className="container">
          <SectionLargeSlider />
        </div>
      </div> */}

      <div className="container relative space-y-24 my-24 lg:space-y-32 lg:my-32">
        {/* SECTION 1 */}
        {/* <SectionSliderCategories /> */}

        {/* SECTION */}
        <div className="relative py-20 lg:py-24">
          <BackgroundSection />
          <SectionBecomeAnAuthor />
        </div>

        {/* <Partners /> */}

        {/* SECTION */}

        {/* SECTION */}
        <div className="mb-24 lg:mb-32" id="contact">
          <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
            Contact Us
          </h2>
          <div className="container max-w-7xl mx-auto">
            <div className="flex-shrink-0 grid grid-cols-1 md:grid-cols-2 gap-12 ">
              <div className="max-w-sm space-y-8">
                {info.map((item, index) => (
                  <div key={index}>
                    <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                      {item.title}
                    </h3>
                    <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                      {item.desc}
                    </span>
                  </div>
                ))}
                <div>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    🌏 SOCIALS
                  </h3>
                  <SocialsList className="mt-2" />
                </div>
              </div>
              <div>
                <form
                  className="grid grid-cols-1 gap-6"
                  onSubmit={handleSubmit}
                >
                  <label className="block">
                    <Label>Full name</Label>

                    <Input
                      placeholder="Example Doe"
                      type="text"
                      className="mt-1"
                      required
                      value={fullName}
                      onChange={handleFullNameChange}
                    />
                  </label>
                  <label className="block">
                    <Label>Email address</Label>

                    <Input
                      type="email"
                      placeholder="example@example.com"
                      className="mt-1"
                      required
                      value={email}
                      onChange={handleEmailChange}
                    />
                  </label>
                  <label className="block">
                    <Label>Message</Label>

                    <Textarea
                      className="mt-1"
                      required
                      rows={6}
                      value={message}
                      onChange={handleMessageChange}
                    />
                  </label>
                  <div>
                    <ButtonPrimary type="submit" loading={contactLoading}>
                      Send Message
                    </ButtonPrimary>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageHome;
