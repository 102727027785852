import React, { FC, useEffect, useState } from "react";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import TimeCountDown from "./TimeCountDown";
import TabDetail from "./TabDetail";
import AccordionInfo from "./AccordionInfo";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import Facebook from "images/socials/facebook.png";
import Instagram from "images/socials/instagram.png";
import Linkedin from "images/socials/linkedin.png";
import Twitter from "images/socials/twitter.png";
import Website from "images/socials/world-wide-web.png";
import Coin from "images/coin.png";
import Token2 from "images/token.png";
import Steps from "components/Steps";
import Progress from "components/Progress";
import { useParams } from "react-router-dom";
import axios from "axios";
import Countdown from "react-countdown";
import { Helmet } from "react-helmet";
import NcModal from "shared/NcModal/NcModal";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { toast } from "react-toastify";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";

export interface NftDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
}

export interface Project {
  id: number;
  company_name: string;
  listing: boolean;
  confidentiality: boolean;
  nda: boolean;
  description: string;
  created_at: number;
  dev_stage_id: DevStage[];
  impact_id: Impact[][];
  days_left: string;
  tokens_id: Token[];
  social_media_id: SocialMedia[];
  fundraising_stages_id: FundraisingStage[];
  promise_id: Promise[];
  image: string;
}

export interface DevStage {
  id: number;
  created_at: number;
  stage: string;
  percentage: string;
}

export interface Impact {
  id: number;
  created_at: number;
  tag: string;
}

export interface Token {
  id: number;
  token_name: string;
  token_symbol: string;
  token_initial_supply: string;
  sold: string;
  price: string;
}

export interface SocialMedia {
  id: number;
  facebook: string;
  instagram: string;
  linkedin: string;
  twitter: string;
  website: string;
}

export interface FundraisingStage {
  id: number;
  title: string;
}

export interface Promise {
  id: number;
  revenue: string[];
  support: string;
  organisations: string;
  estimate_description: string;
  estimate: string[];
  sponsor: string[];
  sponsor_price: string;
  month_est: string[];
  total_est: string[];
}

export interface Image {
  url: string;
  name: string;
}

const NftDetailPage: FC<NftDetailPageProps> = ({
  className = "",
  isPreviewMode,
}) => {
  const { id } = useParams();

  const [startup, setStartup] = useState<Project>();

  const [calculatedTokens, setCalculatedTokens] = useState();

  const [quantity, setQuantity] = useState("");

  const [showModal, setShowModal] = useState(false);

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [contactLoading, setContactLoading] = useState(false);

  const handleFullNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFullName(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setMessage(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent) => {
    // prevent the default form submission behavior
    setContactLoading(true);
    event.preventDefault();

    const data = {
      full_name: fullName,
      email: email,
      message: message,
    };

    axios
      .post("https://xyxm-adm5-et4s.n7.xano.io/api:e0mV796I/contact", data)
      .then((response) => {
        setContactLoading(false);
        toast.success("Message sent successfully");
        setFullName("");
        setEmail("");
        setMessage("");
        setShowModal(false);
      })
      .catch((error) => {
        // handle error
        console.log(error);
        setContactLoading(false);
      });
  };

  useEffect(() => {
    axios
      .get(`https://xyxm-adm5-et4s.n7.xano.io/api:e0mV796I/startups/${id}`)
      .then((response) => {
        setStartup(response.data);
      })
      .catch((error) => {
        console.log("err", error);
      });
  }, []);

  const renderContent = () => {
    return (
      <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
        <p>
          Innovative business models and technology are often ahead of legal and
          administrative infrastructures, we are working on those aspects before
          starting the fundraises.
        </p>
        <label className="block">
          <Label>Full name</Label>

          <Input
            placeholder="Example Doe"
            type="text"
            className="mt-1"
            required
            value={fullName}
            onChange={handleFullNameChange}
          />
        </label>
        <label className="block">
          <Label>Email address</Label>

          <Input
            type="email"
            placeholder="example@example.com"
            className="mt-1"
            required
            value={email}
            onChange={handleEmailChange}
          />
        </label>
        <label className="block">
          <Label>Message</Label>

          <Textarea
            className="mt-1"
            required
            rows={6}
            value={message}
            onChange={handleMessageChange}
          />
        </label>
        <div>
          <ButtonPrimary type="submit" loading={contactLoading}>
            keep me posted
          </ButtonPrimary>
        </div>
      </form>
    );
  };

  return (
    <div
      className={`nc-NftDetailPage  ${className} mb-10`}
      data-nc-id="NftDetailPage"
    >
      <Helmet>
        <title>{startup ? startup?.company_name : ""} - KARAMA</title>
      </Helmet>
      {/* MAIn */}
      <main className="container mt-11 flex ">
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-10 md:gap-14">
          {/* CONTENT */}
          <div className="space-y-8 lg:space-y-10">
            {/* HEADING */}
            <Steps startup={startup} />
            <div className="relative mt-12">
              <NcImage
                src={startup?.image}
                containerClassName="aspect-w-11 aspect-h-12 rounded-3xl overflow-hidden"
              />
              <div className="w-full flex flex-row mt-2 gap-2 flex-wrap">
                {startup?.impact_id.map((tag: Array<any>, index: number) => (
                  <div
                    className="bg-neutral-800 hover:bg-neutral-900 rounded-full w-fit"
                    key={index}
                  >
                    <h2 className="px-4 py-1 text-white text-md">
                      #{tag[0].tag}
                    </h2>
                  </div>
                ))}
              </div>
            </div>

            <AccordionInfo startup={startup} />
          </div>

          {/* SIDEBAR */}
          <div className="pt-10 lg:pt-0 xl:pl-10 border-t-2 border-neutral-200 dark:border-neutral-700 lg:border-t-0">
            <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
              {/* ---------- 1 ----------  */}
              <div className="pb-9 space-y-5">
                <div className="flex justify-between items-center">
                  <Badge
                    name={startup?.fundraising_stages_id[0].title}
                    color="green"
                  />
                  {/* <LikeSaveBtns /> */}
                </div>
                <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold capitalize">
                  {startup?.company_name}
                </h2>

                {/* ---------- 4 ----------  */}
                <div className="flex flex-row gap-8">
                  {startup?.social_media_id[0].facebook &&
                    startup?.social_media_id[0]?.facebook !== "" && (
                      <a
                        href={startup?.social_media_id[0]?.facebook}
                        target="_blank"
                        rel="noreferrer"
                        className="cursor-pointer"
                      >
                        {" "}
                        <img className="w-10" src={Facebook} alt="facebook" />
                      </a>
                    )}

                  {startup?.social_media_id[0]?.instagram &&
                    startup?.social_media_id[0].instagram !== "" && (
                      <a
                        href={startup?.social_media_id[0]?.instagram}
                        target="_blank"
                        rel="noreferrer"
                        className="cursor-pointer"
                      >
                        {" "}
                        <img className="w-10" src={Instagram} alt="Instagram" />
                      </a>
                    )}

                  {startup?.social_media_id[0]?.linkedin &&
                    startup?.social_media_id[0].linkedin !== "" && (
                      <a
                        href={startup?.social_media_id[0]?.linkedin}
                        target="_blank"
                        rel="noreferrer"
                        className="cursor-pointer"
                      >
                        {" "}
                        <img className="w-10" src={Linkedin} alt="Linkedin" />
                      </a>
                    )}

                  {startup?.social_media_id[0]?.twitter &&
                    startup?.social_media_id[0].twitter !== "" && (
                      <a
                        href={startup?.social_media_id[0]?.twitter}
                        target="_blank"
                        rel="noreferrer"
                        className="cursor-pointer"
                      >
                        {" "}
                        <img className="w-10" src={Twitter} alt="Twitter" />
                      </a>
                    )}

                  {startup?.social_media_id[0]?.website &&
                    startup?.social_media_id[0]?.website !== "" && (
                      <a
                        href={startup?.social_media_id[0]?.website}
                        target="_blank"
                        rel="noreferrer"
                        className="cursor-pointer"
                      >
                        {" "}
                        <img className="w-10" src={Website} alt="Website" />
                      </a>
                    )}
                </div>
              </div>

              <div className="py-9">
                <Countdown
                  date={new Date(startup ? startup.days_left : 0)}
                  intervalDelay={0}
                  precision={3}
                  renderer={({ days, hours, minutes, seconds, completed }) => {
                    return (
                      <div className="space-y-5">
                        <div className="text-neutral-500 dark:text-neutral-400 flex items-center space-x-2 ">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M20.75 13.25C20.75 18.08 16.83 22 12 22C7.17 22 3.25 18.08 3.25 13.25C3.25 8.42 7.17 4.5 12 4.5C16.83 4.5 20.75 8.42 20.75 13.25Z"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M12 8V13"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M9 2H15"
                              stroke="currentColor"
                              strokeWidth="1.5"
                              strokeMiterlimit="10"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <span className="leading-none mt-1">
                            Auction ending in:
                          </span>
                        </div>
                        <div className="flex space-x-5 sm:space-x-10">
                          <div className="flex flex-col ">
                            <span className="text-2xl sm:text-2xl font-semibold">
                              {days}
                            </span>
                            <span className="sm:text-lg text-neutral-500 dark:text-neutral-400">
                              Days
                            </span>
                          </div>
                          <div className="flex flex-col ">
                            <span className="text-2xl sm:text-2xl font-semibold">
                              {hours}
                            </span>
                            <span className="sm:text-lg text-neutral-500 dark:text-neutral-400">
                              hours
                            </span>
                          </div>
                          <div className="flex flex-col ">
                            <span className="text-2xl sm:text-2xl font-semibold">
                              {minutes}
                            </span>
                            <span className="sm:text-lg text-neutral-500 dark:text-neutral-400">
                              minutes
                            </span>
                          </div>
                          <div className="flex flex-col ">
                            <span className="text-2xl sm:text-2xl font-semibold">
                              {seconds}
                            </span>
                            <span className="sm:text-lg text-neutral-500">
                              seconds
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  }}
                />
              </div>
              <div className="py-9">
                <Progress startup={startup} />
              </div>

              {/* ---------- 7 ----------  */}
              {/* PRICE */}
              <div className="border rounded-xl px-4 py-4 border-black	custom-card">
                <div className="mb-6 flex flex-col">
                  <div className="flex flex-row">
                    <div className="cursor-pointer ">
                      {" "}
                      <img className="w-6 mr-2" src={Coin} alt="facebook" />
                    </div>
                    <h2 className="font-semibold text-lg">STARTUP TOKEN</h2>
                    <p className="ml-auto text-3xl font-semibold text-primary-6000">
                      {startup?.tokens_id[0]?.price}$
                    </p>
                  </div>
                  <p className="mt-2 text-sm">
                    {startup?.promise_id[0]?.estimate_description}
                  </p>
                  <p className="mt-2 text-lg font-semibold">
                    EST. {startup?.promise_id[0]?.estimate[0]}% TO{" "}
                    {startup?.promise_id[0]?.estimate[1]}%.
                  </p>
                </div>
                <div className="flex flex-col justify-center items-center">
                  <div className="flex flex-row items-baseline p-4 border-2 border-[#F1A300] rounded-xl relative w-[250px]">
                    <span className="absolute bottom-full translate-y-1 py-1 px-1.5 bg-white dark:bg-neutral-900 text-sm text-neutral-500 dark:text-neutral-400">
                      ${startup?.tokens_id[0]?.price} USD/ UNIT
                    </span>
                    <input
                      className="border-none focus:border-none active:border-none focus:outline-0 active:outline-0	text-4xl w-[100px] text-[#F1A300] font-medium dark:bg-neutral-900"
                      name="quantity"
                      placeholder="0"
                      value={quantity}
                      onChange={(e) => {
                        if (!Number.isNaN(e.target.value)) {
                          setQuantity(e.target.value);
                        } else {
                          setQuantity("0");
                        }
                      }}
                    />
                    <span className="text-3xl xl:text-4xl font-semibold text-[#F1A300] ml-auto">
                      USD
                    </span>
                  </div>
                </div>

                {startup && quantity && (
                  <h2 className="text-lg text-center mt-4">
                    TOTAL{" "}
                    <span className="text-3xl font-semibold">
                      {(
                        parseInt(quantity) /
                        parseInt(startup.tokens_id[0].price)
                      ).toFixed(0)}
                    </span>{" "}
                    STARTUP TOKENS
                  </h2>
                )}
                <div className="mt-4 flex flex-col sm:flex-row  max-w-[300px] justify-center items-center mx-auto">
                  <ButtonPrimary
                    onClick={() => {
                      setShowModal(true);
                    }}
                    className="mx-auto"
                  >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M18.04 13.55C17.62 13.96 17.38 14.55 17.44 15.18C17.53 16.26 18.52 17.05 19.6 17.05H21.5V18.24C21.5 20.31 19.81 22 17.74 22H6.26C4.19 22 2.5 20.31 2.5 18.24V11.51C2.5 9.44001 4.19 7.75 6.26 7.75H17.74C19.81 7.75 21.5 9.44001 21.5 11.51V12.95H19.48C18.92 12.95 18.41 13.17 18.04 13.55Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.5 12.4101V7.8401C2.5 6.6501 3.23 5.59006 4.34 5.17006L12.28 2.17006C13.52 1.70006 14.85 2.62009 14.85 3.95009V7.75008"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M22.5588 13.9702V16.0302C22.5588 16.5802 22.1188 17.0302 21.5588 17.0502H19.5988C18.5188 17.0502 17.5288 16.2602 17.4388 15.1802C17.3788 14.5502 17.6188 13.9602 18.0388 13.5502C18.4088 13.1702 18.9188 12.9502 19.4788 12.9502H21.5588C22.1188 12.9702 22.5588 13.4202 22.5588 13.9702Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7 12H14"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span className="ml-2.5">
                      Buy{" "}
                      {startup &&
                        quantity &&
                        (
                          parseInt(quantity) /
                          parseInt(startup.tokens_id[0].price)
                        ).toFixed(0)}{" "}
                      Startup Token
                    </span>
                  </ButtonPrimary>
                </div>
                <div className="flex flex-row justify-around mt-6">
                  <p>
                    Month €{startup?.promise_id[0]?.month_est[0]} TO €
                    {startup?.promise_id[0]?.month_est[1]}
                  </p>
                  <p>
                    EST. TOTAL VALUE ${startup?.promise_id[0]?.total_est[0]}/$
                    {startup?.promise_id[0]?.total_est[0]} USD
                  </p>
                </div>
              </div>
              <div className="border rounded-xl px-4 py-4 border-black	custom-card mt-8">
                <div className="mb-6 flex flex-col">
                  <div className="flex flex-row">
                    <div className="cursor-pointer ">
                      {" "}
                      <img className="w-7 mr-2" src={Token2} alt="facebook" />
                    </div>
                    <h2 className="font-semibold text-lg">
                      SUPER VILAN SPONSOR
                    </h2>
                    <p className="ml-auto text-3xl font-semibold text-primary-6000">
                      {startup?.promise_id[0]?.sponsor_price}$
                    </p>
                  </div>
                  <p className="mt-2 text-sm font-semibold">
                    Thanks From The Founders And Mention Across All Com.
                    Channels
                  </p>
                  <ul className="list-disc	mx-8">
                    {startup?.promise_id[0]?.sponsor.map(
                      (item: any, index: number) => (
                        <li key={index} className="mt-2 text-sm">
                          {item}
                        </li>
                      )
                    )}
                  </ul>
                </div>

                <div className="mt-4 flex flex-col sm:flex-row  max-w-[300px] justify-center items-center mx-auto">
                  <ButtonPrimary
                    onClick={() => {
                      setShowModal(true);
                    }}
                    className="mx-auto"
                  >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path
                        d="M18.04 13.55C17.62 13.96 17.38 14.55 17.44 15.18C17.53 16.26 18.52 17.05 19.6 17.05H21.5V18.24C21.5 20.31 19.81 22 17.74 22H6.26C4.19 22 2.5 20.31 2.5 18.24V11.51C2.5 9.44001 4.19 7.75 6.26 7.75H17.74C19.81 7.75 21.5 9.44001 21.5 11.51V12.95H19.48C18.92 12.95 18.41 13.17 18.04 13.55Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.5 12.4101V7.8401C2.5 6.6501 3.23 5.59006 4.34 5.17006L12.28 2.17006C13.52 1.70006 14.85 2.62009 14.85 3.95009V7.75008"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M22.5588 13.9702V16.0302C22.5588 16.5802 22.1188 17.0302 21.5588 17.0502H19.5988C18.5188 17.0502 17.5288 16.2602 17.4388 15.1802C17.3788 14.5502 17.6188 13.9602 18.0388 13.5502C18.4088 13.1702 18.9188 12.9502 19.4788 12.9502H21.5588C22.1188 12.9702 22.5588 13.4202 22.5588 13.9702Z"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7 12H14"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <span className="ml-2.5">Become A Sponsor Now</span>
                  </ButtonPrimary>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      {/* OTHER SECTION */}
      <NcModal
        renderTrigger={() => null}
        isOpenProp={showModal}
        renderContent={renderContent}
        contentExtraClass="max-w-md"
        onCloseModal={() => setShowModal(false)}
        modalTitle="We are on it !"
      />
    </div>
  );
};

export default NftDetailPage;
