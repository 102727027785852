import { FC, useState, useEffect } from "react";
import HeaderFilterSection from "components/HeaderFilterSection";
import CardNFT2 from "components/CardNFT2";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from "axios";

      interface Impact {
      id: number;
      created_at: number;
      tag: string;
    }

    interface Project {
      id: number;
      impact_id: Impact[][];
    }
//
export interface SectionGridFeatureNFT2Props {}

const SectionGridFeatureNFT2: FC<SectionGridFeatureNFT2Props> = () => {

  const [startUps, setStartUps] = useState([]);
  const [filteredStartUps, setFilteredStartUps] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [showLimit, setShowLimit] = useState(3);


    function filterProjectsByTags(projects: Project[], tags: string[]): any {
      return projects.filter(project => {
        return project.impact_id.some(impacts => {
          return impacts.some(impact => tags.includes(impact.tag.toLowerCase()));
        });
      });
    }


    

  useEffect(() => {
      axios.get('https://xyxm-adm5-et4s.n7.xano.io/api:e0mV796I/startups')
        .then(response => {
          setStartUps(response.data)
          setFilteredStartUps(response.data)
        })
        .catch(error => {
          console.log('err', error);
        });

  }, []);


  useEffect(() => {
    if(filteredCategories.length === 0) {
      setFilteredStartUps(startUps);
      setShowLimit(3);
      return;
    }else {
    setFilteredStartUps(filterProjectsByTags(startUps, filteredCategories));
    setShowLimit(3);
    }
  }, [filteredCategories])


  return (
    <div className="nc-SectionGridFeatureNFT2 relative">
      <HeaderFilterSection setFilteredCategories={setFilteredCategories} />
      <div className={`grid gap-6 lg:gap-8 sm:grid-cols-2 xl:grid-cols-3`}>
        {filteredStartUps.slice(0,showLimit).map((data, index) => (
          <CardNFT2 data={data}  key={index} />
        ))}
      </div>
      <div className="flex mt-16 justify-center items-center">
        <ButtonPrimary
        disabled={showLimit >= filteredStartUps.length}
        onClick={() => {
          setShowLimit(showLimit + 3);
        }} >Show me more</ButtonPrimary>
      </div>
    </div>
  );
};

export default SectionGridFeatureNFT2;




















