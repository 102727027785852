import React from "react";
import banner from "images/banner2.jpg";
import bannerMobile from "images/banner-mobile.png";

const Ads = () => {
  return (
    <div className="my-20">
      {" "}
      <img
        className="w-full rounded-2xl hidden sm:block"
        src={banner}
        alt="hero"
      />
      <img
        className="w-full rounded-2xl sm:hidden"
        src={bannerMobile}
        alt="hero"
      />
    </div>
  );
};

export default Ads;
