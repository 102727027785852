import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React from "react";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Getting started",
    menus: [
      { href: "#", label: "Installation" },
    ],
  },
  {
    id: "1",
    title: "Explore",
    menus: [
      { href: "#", label: "Design features" },
    ],
  },
  {
    id: "2",
    title: "Resources",
    menus: [
      { href: "#", label: "Best practices" },
    ],
  },
  {
    id: "4",
    title: "Community",
    menus: [
      { href: "#", label: "Discussion Forums" },
    ],
  },
];

const Footer: React.FC = () => {
  return (
    <div className="nc-Footer relative py-2 lg:pt-10 lg:pb-10 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container">
        <div className="w-full">
          <div className="flex flex-col justify-center items-center">
            <Logo />
          </div>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400 text-center mt-4">
                   Copyright © All Right Reserved. KARAMA Network LLC.
          </span>
        </div>
        {/* {widgetMenus.map(renderWidgetMenuItem)} */}
      </div>
    </div>
  );
};

export default Footer;
