import {FC} from "react";

export interface ProgressProps {
    startup: any;
}

const Progress: FC<ProgressProps> = ({startup }) => {
    return (
        <>
            <div className="w-full mx-auto">
                <div className="flex pb-2 flex-col">
                    <p className="text-lg text-primary-6000 ">{startup?.tokens_id[0]?.sold}/ {startup?.tokens_id[0]?.token_initial_supply} Token sold</p>
                    <p className="text-lg text-gray-800">{startup?.tokens_id[0]?.sold * startup?.tokens_id[0]?.price} / {startup?.tokens_id[0]?.token_initial_supply * startup?.tokens_id[0]?.price} USD</p>
                </div>
                <div className="flex items-center">
                    <div className="w-full bg-gray-200 h-1 mr-1 relative">
                        <div className={`h-1 bg-primary-6000`} style={{
                            width: `${startup?.tokens_id[0]?.sold / startup?.tokens_id[0]?.token_initial_supply * 100}%`
                        }}/>
                    </div>
                </div>
            </div>
        </>
    );
};


export default Progress;
