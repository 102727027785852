import { FC, useState, useEffect } from "react";

export interface StepsProps {
  className?: string;
  startup?: any;
}

const Steps: FC<StepsProps> = ({ startup }) => {
  const [step, setStep] = useState(2);

  //   useEffect(() => {
  //     if (startup) {
  //       setStep(startup?.dev_stage_id[0]?.id);
  //     }
  //   }, [startup]);

  return (
    <>
      <div className="mx-auto pb-4 w-[90%]">
        <div className="bg-gray-200 h-1 flex items-center justify-between">
          <div
            className={`${
              step >= 1 ? "bg-primary-6000" : "bg-gray-200"
            }  flex justify-between h-1 items-center relative`}
          >
            <div className="absolute right-0 -mr-2">
              <div className="relative  px-2 py-1 rounded mt-16 -mr-12">
                <p className="text-primary-6000 text-xs font-bold dark:text-gray-100">
                  Conception
                </p>
              </div>
            </div>
          </div>
          <div
            className={`${
              step >= 2 ? "bg-primary-6000" : "bg-gray-200"
            } w-1/3 flex justify-between h-1 items-center relative`}
          >
            <div className="absolute right-0 -mr-2">
              <div className="relative  px-2 py-1 rounded mt-16 -mr-12">
                <p className="text-primary-6000 text-xs font-bold dark:text-gray-100">
                  Developing
                </p>
              </div>
            </div>
            <div
              className={
                step >= 1
                  ? "bg-primary-6000 h-6 w-6 rounded-full shadow flex items-center justify-center -ml-2"
                  : "bg-gray-200 h-6 w-6 rounded-full shadow flex items-center justify-center -ml-2"
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-check"
                width={18}
                height={18}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#FFFFFF"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <path d="M5 12l5 5l10 -10" />
              </svg>
            </div>
          </div>
          <div
            className={`${
              step >= 3 ? "bg-primary-6000" : "bg-gray-200"
            } w-1/3 flex justify-between  h-1 items-center relative`}
          >
            <div className="absolute right-0 -mr-2">
              <div className="relative  px-2 py-1 rounded mt-16 -mr-12">
                <p className="text-primary-6000 text-xs font-bold dark:text-gray-100">
                  Testing
                </p>
              </div>
            </div>
            <div
              className={
                step >= 2
                  ? "bg-primary-6000 h-6 w-6 rounded-full shadow flex items-center justify-center -ml-2"
                  : "bg-gray-200 h-6 w-6 rounded-full shadow flex items-center justify-center -ml-2"
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-check"
                width={18}
                height={18}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#FFFFFF"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <path d="M5 12l5 5l10 -10" />
              </svg>
            </div>
            {/* <div className="bg-white h-6 w-6 rounded-full shadow flex items-center justify-center -mr-3 relative">
                            <div className="h-3 w-3 bg-primary-6000 rounded-full" />
                        </div> */}
          </div>
          <div
            className={`${
              step >= 4 ? "bg-primary-6000" : "bg-gray-200"
            } w-1/3 flex justify-between  h-1 items-center relative`}
          >
            <div className="absolute right-[20px] -mr-2">
              <div className="relative  px-2 py-1 rounded mt-16 -mr-12">
                <p className="text-primary-6000 text-xs font-bold dark:text-gray-100">
                  Selling
                </p>
              </div>
            </div>
            <div
              className={
                step >= 3
                  ? "bg-primary-6000 h-6 w-6 rounded-full shadow flex items-center justify-center -ml-2"
                  : "bg-gray-200 h-6 w-6 rounded-full shadow flex items-center justify-center -ml-2"
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-check"
                width={18}
                height={18}
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#FFFFFF"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <path d="M5 12l5 5l10 -10" />
              </svg>
            </div>
            <div
              className={
                step >= 4
                  ? "bg-primary-6000 h-6 w-6 rounded-full shadow flex items-center justify-center -ml-2"
                  : "bg-gray-200 h-6 w-6 rounded-full shadow flex items-center justify-center -ml-2"
              }
            >
              <div className="h-3 w-3 bg-gray-200 rounded-full" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Steps;
